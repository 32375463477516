<template>
  <div class="pa-3 ">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="show"
        max-width="500px"
        persistent
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    dense
                    v-model="editedItem.name"
                    label="Nom"
                    required
                    :rules="[(v) => !!v || 'Nom obligatoire']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-select
                    :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                    dense
                    v-model="editedItem.notation"
                    label="Notation"
                    required
                    :rules="[(v) => !!v || 'Notation obligatoire']"
                    chips
                    class="mt-2"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    type="number"
                    class="inputPrice"
                    v-model="editedItem.tel"
                    label="Télephone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="editedItem.email"
                    label="Email"
                    :rules="[
                      (v) =>
                        !v || /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="show = false">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="save">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATECLIENT from "../graphql/Devis/CREATECLIENT.gql";
import UPDATECLIENT from "../graphql/Devis/UPDATECLIENT.gql";

export default {
  name: "clientform",
  props: {
    client_id: {
      type: Number,
      //required: true,
    },
    value: Boolean,
  },
  data: () => ({
    afficher: false,
    valid: true,

    datepicker1: false,

    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: { name: "", notation: "", tel: "", email: "" },
    editedItem: {},
  }),

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    formTitle() {
      return this.client_id == "-1"
        ? "Nouveau Client "
        : "Editer Client  : " + this.editedItem.name;
    },
  },
  watch: {
    dialog() {
      this.afficher = this.dialog;
    },
  },

  created() {
    if (this.client_id != -1)
      this.editedItem = Object.assign(
        {},
        this.$store.state.client_list.find((c) => c.id == this.client_id)
      );
    else this.editedItem = Object.assign({}, this.defaultItem);
  },

  methods: {
    save() {
      if (this.$refs.form.validate()) {
        if (this.client_id > -1) {
          this.$apollo
            .mutate({
              mutation: UPDATECLIENT,
              variables: {
                client: {
                  id: this.editedItem.id,
                  notation: this.editedItem.notation,
                  name: this.editedItem.name,
                  tel: this.editedItem.tel,
                  email: this.editedItem.email,
                  write_uid: this.$store.state.me.id,
                },
              },
            })
            .then(() => {
              this.snackbar_text = "Update success";
              this.snackbar_color = "success";
              this.snackbar = true;
              this.$store.dispatch("UPDATE_CLIENT", this.editedItem);
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
        } else {
          this.$apollo
            .mutate({
              mutation: CREATECLIENT,
              variables: {
                client: {
                  notation: parseInt(this.editedItem.notation),
                  name: this.editedItem.name,
                  tel: this.editedItem.tel,
                  email: this.editedItem.email,
                  create_uid: parseInt(this.$store.state.me.id),
                  write_uid: parseInt(this.$store.state.me.id),
                },
              },
            })
            .then((data) => {
              this.snackbar_color = "success";
              this.snackbar_text = "Add success";
              this.snackbar = true;
              this.editedItem.id = data.data.createDevisClient.id;
              this.editedItem.created_at =
                data.data.createDevisClient.created_at;
              this.$store.dispatch("ADD_CLIENT", this.editedItem);
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_color = "error";
              this.snackbar_text = error.message;
              this.snackbar = true;
            });
        }
        this.$refs.form.resetValidation();
        this.show = false;
      }
    },
  },
};
</script>

<style>
tr.v-data-table__selected {
  background: rgb(158, 184, 255) !important;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.inputPrice input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.v-select__selection {
  width: 100%;
  justify-content: center;
}
</style>
